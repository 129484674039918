.mapboxgl-popup {
    border-radius: 8px;
    overflow: hidden;
    user-select: none;
}
.mapboxgl-popup-content {
    padding: 0 !important;
    border-radius: 16px !important;
}
.mapboxgl-popup-tip {
    display: none;
}
